import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import * as moment from 'moment';
import { config } from 'rxjs';
import { Constants } from '../../configurations/constants';
import { RouteConstants } from '../../configurations/route-constants';
import { UserRoles } from '../../configurations/user-roles';
import { GridParams } from '../../models/config/gridparams.model';
import { SearchVaultRequestViewModel } from '../../models/vault/vault-serach-request.model';
import { ConfigService } from '../../services/core/config.service';
import { GlobalService } from '../../services/core/global.service';
import { Utilities } from '../../services/core/utilities';
import { SubService } from '../../services/media/sub.service';
import { VaultService } from '../../services/vault/vault.service';

@Component({
  selector: 'app-vault',
  templateUrl: './vault.component.html',
  styleUrls: ['./vault.component.css']
})
export class VaultComponent implements AfterViewInit, OnInit {

  public resultStagingVaultViewStore: any = {};
  public resultVaultStore: any = {};
  public searchVaultCriteriaObj = <SearchVaultRequestViewModel>{};
  public searchStageCriteriaObj = <SearchVaultRequestViewModel>{};

  public activeGrid: string = Constants.openVaultRequests;

  public openVaultsGrid = Constants.openVaultRequests;
  public stagingVaultsGrid = Constants.stagingVaults;

  @ViewChild('vaultSearch', { static: true }) vaultSearch: DxDataGridComponent;
  @ViewChild('stageVaultGrid', { static: false }) stageVaultGrid: DxDataGridComponent;

  constructor(
    public util: Utilities,
    public userRoles: UserRoles,
    private vaultService: VaultService,
    private router: Router,
    public configService: ConfigService,
    private sub: SubService,
    private gs: GlobalService) {

  }

  ngAfterViewInit(): void {

  }

  ngOnInit() {
    this.searchVaultCriteriaObj.isStaging = false;
    this.searchVaultCriteriaObj.isOpenRequests = true;

    this.searchStageCriteriaObj.isStaging = true;
    this.searchStageCriteriaObj.isOpenRequests = false;

    this.loadVaults();
    this.loadStagingVaults();

    this.sub.vaultRequestCompletedMethodCalled.subscribe(() => {
      this.searchVaultCriteriaObj.isOpenRequests = true;
      this.vaultSearch.instance.refresh();
    });
  }

  gotoVaultCreate() {
    this.router.navigate([RouteConstants.createvaultRoute]);
  }

  public onclickOpenVaultRequests() {
    this.activeGrid = Constants.openVaultRequests;
    this.searchVaultCriteriaObj.isStaging = false;
    this.searchVaultCriteriaObj.isOpenRequests = true;
    this.loadVaults();
  }

  public onclickStaging() {
    this.activeGrid = Constants.stagingVaults;
    this.searchVaultCriteriaObj.isStaging = true;
    this.searchVaultCriteriaObj.isOpenRequests = false;
    this.loadStagingVaults();
  }

  public onRowPrepared(e: any) {
    if (e.rowType === "data") {
      e.rowElement.css({ height: 54 });
    }
  }

  public loadStagingVaults() {
    try {
      var vaultService = this.vaultService;
      var util = this.util;
      var criteriaObj = this.searchStageCriteriaObj;
      this.resultStagingVaultViewStore.store = new CustomStore({
        key: "vaultRequestGuid",
        load: function (loadOptions: any) {
          var skip = loadOptions.skip;
          var take = loadOptions.take;
          var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";

          let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: (loadOptions.isLoadingAll && loadOptions.isLoadingAll === true) };
          let request: any = { criteria: criteriaObj, GridParams: gridParams };

          return vaultService.searchVaultRequests(request)
            .toPromise()
            .then((response: any) => {
              console.log(response);
              if (response.isSuccess == true) {
                var vaultSearchResponse = response.result;
                var obj: any = {
                  data: vaultSearchResponse.searchResults,
                  totalCount: vaultSearchResponse.totalCount
                };
                return obj;
              }
              else {
                util.handleIsNotSuccess(response.errors);
                throw 'Data Loading Error';
              }
            })
            .catch(error => {
              console.log(error);
              util.handleError(error);
              throw 'Data Loading Error';
            });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  public loadVaults() {
    try {
      var vaultService = this.vaultService;
      var util = this.util;
      var criteriaObj = this.searchVaultCriteriaObj;

      this.resultVaultStore.store = new CustomStore({
        key: "vaultRequestGuid",
        load: function (loadOptions: any) {
          var skip = loadOptions.skip;
          var take = loadOptions.take;
          var sortOptions = loadOptions.sort ? JSON.stringify(loadOptions.sort) : "[{'selector':'createdDate','desc':true}]";

          let gridParams: GridParams = { group: null, skip: skip, take: take, sort: sortOptions, isExport: (loadOptions.isLoadingAll && loadOptions.isLoadingAll === true) };
          let request: any = { criteria: criteriaObj, GridParams: gridParams };

          return vaultService.searchVaultRequests(request)
            .toPromise()
            .then((response: any) => {
              if (response.isSuccess == true) {
                var vaultSearchResponse = response.result;
                var obj: any = {
                  data: vaultSearchResponse.searchResults,
                  totalCount: vaultSearchResponse.totalCount
                };
                return obj;
              }
              else {
                util.handleIsNotSuccess(response.errors);
                throw 'Data Loading Error';
              }
            })
            .catch(error => {
              console.log(error);
              util.handleError(error);
              throw 'Data Loading Error';
            });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  public showVaultRequesDetail(vaultRequestGuid, statusLabel) {
    if (vaultRequestGuid == "00000000-0000-0000-0000-000000000000")
      return;

    this.gs.openVaultRequestFile(vaultRequestGuid, statusLabel);
  }
}
