import { Component, OnInit, ViewChild, TemplateRef, ElementRef, NgZone, Input, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { Utilities } from "../../../services/core/utilities";
import { AlertService, MessageSeverity } from '../../../services/core/alert.service';
import { Constants } from "../../../configurations/constants";
import { commentTypeEnum, approvalStatusEnum } from "../../../configurations/enums/enums";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { MediaDetailService } from "../../../services/mediadetail/mediadetail.service";
import { SpotMediaDetail, SpotComment } from "../../../models/spot/spotdetail-model";
import * as moment from 'moment-timezone';
import { TimecodeFormat } from "../../../pipes/timecodeformat.pipe";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SpotUpdateApprovalRequest } from "../../../models/spot/spot-update-approval-request.model";
import { ShareMediaPopupComponent } from "../../popups/sharemediapopup/sharemediapopup.component";
import { UserRoles } from "../../../configurations/user-roles";
import { SpotEditRequest } from "../../../models/spot/spot.edit-request";
import { ErrorPopupComponent } from "../../popups/errorpopup/errorpopup.component";
import { EditHistoryPopupComponent } from "../../popups/edithistorypopup/edithistorypopup.component";
import { SpotNotificationPopupComponent } from "../../popups/spotnotificationspopup/spotnotificationspopup.component";
import { DxTreeViewComponent } from 'devextreme-angular';
import { ConfigService } from "../../../services/core/config.service";
import { SpotOrderHistoryPopupComponent } from '../../popups/spotorderhistorypopup/spotorderhistorypopup.component';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { SpotService } from '../../../services/media/spot.service';
import { GlobalService } from '../../../services/core/global.service';
import { AddEditMediaCustomTagPopUp } from '../../popups/addeditmediacustomtagpopup/addeditmediacustomtagpopup.component';
import { MediaCustomTagAddEditRequest } from '../../../models/spot/addeditmediacustomtag.model';
import { PurgeMediaPopupComponent } from '../../popups/purgemediapopup/purgemediapopup.component';
import { DownloadMediaService } from '../../../services/media/media-download.service';
import { SpotFile } from '../../../models/spot/spot-file.model';
import { UserProfileService } from '../../../services/user/user-profile.service';
import { RouteConstants } from '../../../configurations/route-constants';
import { PurgeMedia } from '../../../models/spot/purgemedia.model';
import { Router } from '@angular/router';
import { customTimeCodeValidator } from '../../../configurations/custom-validations';

@Component({
  selector: 'mediadetailcontent',
  templateUrl: './mediadetailcontent.component.html',
  styleUrls: ['./mediadetailcontent.component.css'],
})
export class MediaDetailContentComponent implements OnInit {

  public spotEditRequest: FormGroup;
  public addGeneralCommentRequest: FormGroup;
  public addTimecodeCommentRequest: FormGroup;
  public approvalRequest: FormGroup;
  public markerValue: number[];
  public spotMediaDetail: SpotMediaDetail;

  @Input() spotFileIdentifier: string;
  @Input() isQuickView: boolean;
  @Input() isPopOverView: boolean;

  public spotFileGuid: string;
  public isPopup: boolean;
  public isPopover: boolean;
  public timeCodeMask = Constants.timeCodeFormatMask;

  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef;
  @ViewChild('fg', { static: false }) gForm;
  @ViewChild('ft', { static: false }) tForm;
  @ViewChild('approverspop', { static: false }) approverspop: PopoverDirective;
  @ViewChild('treeMarker', { static: false }) treeMarker: DxTreeViewComponent;
  @ViewChild('hiddenImage', { static: false }) hiddenImage: ElementRef;
  @ViewChild('treeUserGrps', { static: false }) treeUserGrps: DxTreeViewComponent;

  public editMode: boolean = false;
  public editorialHouseDataSource: any;
  public agenciesDataSource: any;
  public clientDataSource: any;
  public brandDataSource: any;
  public productDataSource: any = [];
  public clientUserGroupDataSource: any = [];
  public formatDataSource: any;
  public aspectRatioDataSource: any;
  public qcStatusDataSource: any;
  public approvalStatusDataSource: any;
  public markerTypesDataSource: any;
  private userApprovalStatus: string = "";
  public isQCNotesRequired: boolean = false;
  public isApprovalNotesRequired: boolean = false;
  private qcFail: string = "QC Fail";
  private rejected: string = "Rejected";
  public selectedTimeCodeRow: number = -1;
  public overyLayHeight: number = 0;
  public userGrpsValue: Array<number>;

  public is169OverlayRequired: boolean = false;
  public isOver169Overlay: boolean = false;
  public isOver43Overlay: boolean = false;

  public proxyUrl: string = null;

  public isClientChanged: boolean = false;
  public bsModalRef: BsModalRef;

  public isSpotDownloadable: boolean = false;
  public playerWidth = 0;
  public videoPlayerInitialized = false;
  playerWidthClass: string = null;
  videoPlayerEle: HTMLVideoElement;

  public thumbnailWidth: number | null = null;
  public thumbnailHieght: number | null = null;

  constructor(ngZone: NgZone,
    private spotService: SpotService,
    public util: Utilities,
    public userRoles: UserRoles,
    private mediaDetailService: MediaDetailService,
    public modalService: BsModalService,
    private alertService: AlertService,
    private configService: ConfigService,
    private ds: DownloadMediaService,
    private router: Router,
    private userProfileService: UserProfileService,
    private gs: GlobalService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2) {

    window.onresize = (e) => {
      ngZone.run(() => {
        this.isOver169Overlay = false;
        this.isOver43Overlay = false;
      });
    };
  }

  ngOnInit() {
    this.spotMediaDetail = null;
    this.spotFileGuid = this.spotFileIdentifier;
    this.isPopup = this.isQuickView;
    this.isPopover = this.isPopOverView;

    this.getDetail();

    this.addGeneralCommentRequest = new FormGroup({
      comments: new FormControl(null, Validators.required)
    });

    this.addTimecodeCommentRequest = new FormGroup({
      comments: new FormControl(null, Validators.required),
      timeCodeIn: new FormControl(null, Validators.required),
      timeCodeOut: new FormControl(null, [Validators.required]),
      timeIn: new FormControl(null),
      timeOut: new FormControl(null)
    });

    this.spotEditRequest = new FormGroup({
      adId: new FormControl(null, Validators.required),
      title: new FormControl(null, Validators.required),
      client: new FormControl(),
      brand: new FormControl(),
      product: new FormControl(),
      editorialHouse: new FormControl(),
      agency: new FormControl(),
      format: new FormControl(),
      aspectRatio: new FormControl(),
      qcStatus: new FormControl(),
      approvalStatus: new FormControl(),
      markers: new FormControl(),
      expiryDate: new FormControl(),
      notes: new FormControl(),
      encoded: new FormControl(),
      duration: new FormControl(),
      approvalNotes: new FormControl(),
      startOfMessage: new FormControl('', Validators.compose([customTimeCodeValidator])),
      endOfMessage: new FormControl('', Validators.compose([customTimeCodeValidator])),
      lkfs: new FormControl(null),
      userGroups: new FormControl()
    });

    this.addGeneralCommentRequest = new FormGroup({
      comments: new FormControl(null, Validators.required)
    });

    this.approvalRequest = new FormGroup({
      comments: new FormControl(null, Validators.required)
    });

    this.spotEditRequest.controls['client'].valueChanges.subscribe(val => {
      this.onClientValueChanged(val);
    });

  }

  //ngAfterViewInit() {
  //  const imgElement = this.hiddenImage.nativeElement;
  //  if (imgElement != null) {
  //    this.renderer.setAttribute(imgElement, 'src', this.spotMediaDetail.thumbnailUrl);
  //  }
  //}

  getThumbnailDimensions(event: any): void {
    // Access the loaded image's dimensions
    const imgElement = event.target as HTMLImageElement;
    if (imgElement != null) {
      const thumbnailWidth = imgElement.width;
      const thumbnailHeight = imgElement.height;

      this.thumbnailHieght = thumbnailHeight;
      this.thumbnailWidth = thumbnailWidth;
    }
    else {
      this.thumbnailWidth = 640;
      this.thumbnailHieght = 360;
    }

    if (this.thumbnailWidth >= 1200 && this.thumbnailHieght > 640) {
      this.playerWidthClass = 'col-md-12'
    }
    else {
      this.playerWidthClass = 'col-md-8'
    }
  }

  //ngAfterViewChecked() {
  //  // Check if the videoPlayer element is initialized and update ngClass accordingly
  //  if (this.videoPlayer && !this.videoPlayerInitialized) {
  //    this.updatePlayerWidth();
  //    this.videoPlayerInitialized = true;
  //    alert(this.videoPlayer);
  //  }
  //}
  //onVideoPlayerLoaded() {
  //  //this.videoPlayerEle = document.getElementById('main-video') as HTMLVideoElement;
  //  //this.updatePlayerWidthClass();

  //}

  //updatePlayerWidthClass() {
  //  // Your logic to update the playerWidthClass based on updatePlayerWidth() value
  //  const width = this.updatePlayerWidth(); // Replace this with your actual function to get the width
  //  this.playerWidthClass = this.getPlayerWidthClass(width);
  //}

  //getPlayerWidthClass(width: number): string {
  //  if (width >= 1280) {
  //    return 'col-md-12';
  //  } else if (width >= 1008 && width < 1280) {
  //    return 'col-md-8';
  //  } else {
  //    return 'col-md-8';
  //  }
  //}

  //updatePlayerWidth(): number {
  //  if (this.videoPlayer && this.videoPlayer.nativeElement) {
  //    const player = this.videoPlayer.nativeElement;
  //    this.playerWidth = player.offsetWidth;
  //    return player.offsetWidth;
  //  }
  //  return 0; // Default width if the video player is not available
  //}

  //updatePlayerWidth() {
  //  if (this.videoPlayer && this.videoPlayer.nativeElement) {
  //    const player = this.videoPlayer.nativeElement;
  //    this.playerWidth = player.offsetWidth;
  //    this.cdr.detectChanges(); // Trigger change detection here
  //  }
  //}

  public onClientValueChanged(val) {
    this.spotEditRequest.controls['brand'].setValue(this.spotMediaDetail.brandId);
    this.spotEditRequest.controls['product'].setValue(this.spotMediaDetail.productId == null ? 0 : this.spotMediaDetail.productId);

    if (val == this.spotMediaDetail.clientId)
      this.isClientChanged = false;
    else
      this.isClientChanged = true;
  }

  public onBrandValueChanged(e) {
    this.getClientBrandProducts(e);
  }

  public onEditorialHouseValueChanged(e) {

  }

  public approvalStatusChange(e) {
    this.isApprovalNotesRequired = this.setApprovalNotesRequiredOrNot();
  }

  public showStatusConfirm(statusConfirm: TemplateRef<any>, isApproved) {

    this.userApprovalStatus = isApproved ? approvalStatusEnum[approvalStatusEnum.Approved] : approvalStatusEnum[approvalStatusEnum.Rejected];

    this.approvalRequest.controls['comments'].setValue("");

    this.bsModalRef = this.modalService.show(statusConfirm, this.util.getModalComponentOptions({}, false, true, false));
  }

  public showEditHistory() {

    var initialState = {
      objectId: this.spotMediaDetail.spotFileGuid,
      objectCategory: 'spot'
    };

    this.bsModalRef = this.modalService.show(EditHistoryPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public purgeMedia() {
    var initialState = {
      spotGuid: this.spotMediaDetail.spotGuid,
      spotFileGuid: this.spotMediaDetail.spotFileGuid
    };

    this.bsModalRef = this.modalService.show(PurgeMediaPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));

    this.bsModalRef.content.onPurgeConfirm.subscribe((result: PurgeMedia) => {
      if (result != null && result != undefined) {
        Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
        result.spotGuid = this.spotMediaDetail.spotGuid;
        result.spotFileGuid = this.spotMediaDetail.spotFileGuid;
        this.mediaDetailService.purgeMedia(result).subscribe((res: any) => {
          if (res.isSuccess == true) {
            var newDetail = res.result as SpotMediaDetail;
            this.refreshSpotDetail(newDetail);
            this.alertService.ShowLoader(false);
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
            this.alertService.ShowLoader(false);
          }
          //var newDetail = result as SpotMediaDetail;
          //if (newDetail.errors == null || newDetail.errors.length == 0) {
          //  //this.alertService.showMessage("SUCCESS", Constants.spotPurgedSuccessfully, MessageSeverity.success);
          //  this.refreshSpotDetail(newDetail);
          //}
          //else {
          //  var initialState = {
          //    errors: newDetail.errors
          //  };
          //  this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
          //}
        },
          error => {
            this.util.handleError(error);
            this.alertService.ShowLoader(false);
          });
      }
    });
  }

  public showOrderHistory() {

    var initialState = {
      spotFileGuid: this.spotMediaDetail.spotFileGuid
    };

    this.bsModalRef = this.modalService.show(SpotOrderHistoryPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public showSpotNotifications() {
    var initialState = {
      spotFileGuid: this.spotMediaDetail.spotFileGuid
    };

    this.bsModalRef = this.modalService.show(SpotNotificationPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  public onAddEditMediaCustomTags() {
    var initialState = {
      spotFileGuid: this.spotMediaDetail.spotFileGuid
    };

    this.bsModalRef = this.modalService.show(AddEditMediaCustomTagPopUp, this.util.getModalComponentOptions(initialState, false, true, false, 'mediaCustomTagPopUp'));

    this.bsModalRef.content.onSaveCustomTags.subscribe((result: MediaCustomTagAddEditRequest) => {
      if (result != null && result != undefined) {
        Promise.resolve(null).then(() => this.alertService.ShowLoader(true));
        this.mediaDetailService.addEditMediaCustomTags(this.spotFileGuid, result).subscribe((res: any) => {
          if (res.isSuccess == true) {
            var newDetail = res.result as SpotMediaDetail;
            this.refreshSpotDetail(newDetail);
          }
          else {
            this.util.handleIsNotSuccess(res.errors);
          }

          this.alertService.ShowLoader(false);
        },
          error => {
            this.util.handleError(error);
            this.alertService.ShowLoader(false);
          });
      }
    });

  }

  public modalClose() {
    this.bsModalRef.hide();
  }

  public updateStatus({ value, valid }: { value: SpotUpdateApprovalRequest, valid: boolean }) {

    if (this.approvalRequest.controls['comments'].errors)
      return;

    value.approvalLevel = this.spotMediaDetail.currentApproverLevel;
    value.approvalStatus = this.userApprovalStatus;

    this.mediaDetailService.submitSpotApproval(this.spotFileGuid, value).subscribe((res: any) => {
      if (res.isSuccess == true) {

        var newDetail = res.result as SpotMediaDetail;

        if (newDetail.errors == null || newDetail.errors.length == 0) {
          this.alertService.showMessage("SUCCESS", Constants.spotsUpdatedSuccessfully, MessageSeverity.success);
          this.util.spotApprovalCount = this.util.spotApprovalCount > 0 ? this.util.spotApprovalCount - 1 : 0;
          this.gs.spotEdited();
        }
        else {
          var initialState = {
            errors: newDetail.errors
          };

          this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
        }

        this.spotMediaDetail = newDetail;

       // this.updatePlayerWidthClass(this.spotMediaDetail); // testing
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });

    this.modalClose();
  }

  public timeIn() {

    var tIn = this.videoPlayer.nativeElement.currentTime;

    this.addTimecodeCommentRequest.controls['timeCodeIn'].setValue(tIn);
    this.addTimecodeCommentRequest.controls['timeIn'].setValue(new TimecodeFormat().transform(tIn, this.spotMediaDetail.mediaSpecs.frameRate));
  }

  public timeOut() {

    var tOut = this.videoPlayer.nativeElement.currentTime;

    //this.addTimecodeCommentRequest.controls['timeCodeOut'].setValue(new TimecodeFormat().transform(this.api.getDefaultMedia().currentTime));
    this.addTimecodeCommentRequest.controls['timeCodeOut'].setValue(tOut);
    this.addTimecodeCommentRequest.controls['timeOut'].setValue(new TimecodeFormat().transform(tOut, this.spotMediaDetail.mediaSpecs.frameRate));
    //this.addTimecodeCommentRequest.controls['timeCodeOut'].setValue(new TimecodeFormat().transform(tOut, this.spotMediaDetail.mediaSpecs.frameRate));
    //this.addTimecodeCommentRequest.controls['timeOut'].setValue(tOut);
  }

  private addSpotComment(comment: SpotComment, isTimeCode: boolean) {
    this.mediaDetailService.addSpotComment(this.spotFileGuid, comment).subscribe((res: any) => {

      if (res.isSuccess == true) {

        var latestComments = res.result as SpotComment[];

        if (isTimeCode == true)
          this.spotMediaDetail.timeCodeComments = latestComments;
        else
          this.spotMediaDetail.generalComments = latestComments;

        this.clearComment(isTimeCode);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public clearComment(isTimeCode: boolean) {
    if (isTimeCode == false) {
      this.addGeneralCommentRequest.reset();
      this.gForm.resetForm();
    }
    else {
      this.addTimecodeCommentRequest.reset();
      this.tForm.resetForm();
    }
  }

  public onSaveGeneralComment({ value, valid }: { value: SpotComment, valid: boolean }) {
    if (this.addGeneralCommentRequest.controls['comments'].errors)
      return;

    value.commentType = (commentTypeEnum[commentTypeEnum.General]);

    this.addSpotComment(value, false);
  }

  public onSaveTimecodeComment({ value, valid }: { value: SpotComment, valid: boolean }) {
    if (this.addTimecodeCommentRequest.controls['comments'].errors || this.addTimecodeCommentRequest.controls['timeCodeIn'].errors || this.addTimecodeCommentRequest.controls['timeCodeOut'].errors)
      return;

    if (this.addTimecodeCommentRequest.controls['timeCodeOut'].value < this.addTimecodeCommentRequest.controls['timeCodeIn'].value) {
      return;
    }

    value.commentType = value.commentType = (commentTypeEnum[commentTypeEnum.TimeCode]);

    this.addSpotComment(value, true);
  }

  public shareSpot() {
    let spotFiles: string[] = [];
    spotFiles.push(this.spotMediaDetail.spotFileGuid);
    let showApproval: boolean = false;
    showApproval = (this.spotMediaDetail.isShareForApprovalAllowed && (this.spotMediaDetail.approvalCompletedDate == null || this.spotMediaDetail.approvalCompletedDate == undefined));
    let currentApproverLevel: number = this.spotMediaDetail.currentApproverLevel;
    //let isMasterDownloadable = this.spotMediaDetail.isMasterDownloadable;  // Commented as task moved to TAN-7
    let isMasterDownloadable = false;

    var initialState = {
      spotFileGuids: spotFiles,
      currentApproverLevel: currentApproverLevel,
      showMasterMediaShare: isMasterDownloadable,
      showApproval: showApproval,
      source: "search"
    };

    this.bsModalRef = this.modalService.show(ShareMediaPopupComponent, this.util.getModalComponentOptions(initialState, false, false, true));
  }

  shareMasterMedia() {
    let spotFiles: string[] = [];
    spotFiles.push(this.spotMediaDetail.spotFileGuid);

    var initialStateShare = {
      spotFileGuids: spotFiles,
      isMasterMediaShare: true,
      albumName: null,
      source: "search"
    };

    this.bsModalRef = this.modalService.show(ShareMediaPopupComponent, this.util.getModalComponentOptions(initialStateShare, false, false, true));
  }

  public onEdit() {
    this.setEditValues();
    this.editMode = true;
  }

  public hasUnsavedData(): boolean {
    return this.editMode;
  }

  public onCancelEdit() {
    this.setEditValues();
    this.editMode = false;
  }

  public onSaveEdit({ value, valid }: { value: SpotEditRequest, valid: boolean }) {
    if (!valid)
      return

    if ((this.isQCNotesRequired && (value.notes == null || value.notes == '')) || (value.title == '') || (this.isApprovalNotesRequired && (value.approvalNotes == null || value.approvalNotes == '')))
      return

    //if ((value.startOfMessage != null && value.startOfMessage != '') && value.endOfMessage == null) {
    //  this.util.handleIsNotSuccess(["start of message and end of message are required."])
    //  return
    //}

    //if ((value.endOfMessage != null && value.endOfMessage != '') && value.startOfMessage == null) {
    //  this.util.handleIsNotSuccess(["start of message and end of message are required."])
    //  return
    //}

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.spotMediaDetail.isProductNotSelected = false;
    value.userGroupIds = this.userGrpsValue;

    this.mediaDetailService.editSpot(this.spotFileGuid, value).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var newDetail = res.result as SpotMediaDetail;
        this.refreshSpotDetail(newDetail);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
      });
  }

  public qcStatusChange(e) {
    this.isQCNotesRequired = this.setQCNotesRequiredOrNot();
  }

  public seekPlayer(time: number, index: number) {
    this.selectedTimeCodeRow = index;
    this.videoPlayer.nativeElement.currentTime = time;
  }

  //public showPendingApprovers: boolean;

  //public toggleApprovers() {
  //  this.showPendingApprovers = !this.showPendingApprovers;
  //}

  public onHideApproversPop() {
    this.approverspop.hide();
  }

  public toggleOverlay(overlayType: string) {
    if (overlayType == '169') {
      this.isOver43Overlay = false;
      this.isOver169Overlay = !this.isOver169Overlay
    }
    else {
      this.isOver169Overlay = false;
      this.isOver43Overlay = !this.isOver43Overlay
    }

    this.overyLayHeight = this.videoPlayer.nativeElement.offsetHeight;
  }

  public videoOut() {
    var overLayButton = document.getElementsByClassName("safe-overlay")[0];
    if (overLayButton != null)
      overLayButton.classList.add('hide');

    this.videoPlayer.nativeElement.controls = true;
  }

  public videoIn() {
    var overLayButton = document.getElementsByClassName("safe-overlay")[0];
    if (overLayButton != null)
      overLayButton.classList.remove('hide');

    this.videoPlayer.nativeElement.controls = false;
  }

  public onPlay() {
    var elt = document.getElementsByClassName("video-overlay")[0];
    elt.classList.add('video-playing');
  }

  public onPause() {
    var elt = document.getElementsByClassName("video-overlay")[0];
    elt.classList.remove('video-playing');
  }

  public onMarkerChanged(e = null) {

    if (e != null && e != undefined && e.component != undefined && e.component != null) {
      e.component.unselectAll();

      if (this.markerValue) {
        this.markerValue.forEach((function (value) {
          e.component.selectItem(value);
        }).bind(this));
      }
    }
    else if (this.treeMarker != null && this.treeMarker != undefined && this.treeMarker.instance != undefined && this.treeMarker.instance != null) {
      this.treeMarker.instance.unselectAll();

      if (this.markerValue) {
        this.markerValue.forEach((function (value) {
          this.treeMarker.instance.selectItem(value);
        }).bind(this));
      };
    }
  }

  public onMarkerTreeChanged(e) {
    this.markerValue = e.component.getSelectedNodesKeys();
  }

  public onPlayerMouseOver(e) {
    this.isOver169Overlay = false;
    this.isOver43Overlay = false;
  }

  private ngOnDestroy() {
    //this.subscription.unsubscribe();
  }

  private getClientBrandProducts(id: number) {
    if (id === null || id <= 0) {
      //this.productDataSource = [];
      return;
    }

    this.configService.getCurrentClientBrandProducts(this.spotMediaDetail.clientId, id).subscribe((res: any) => {
      if (res.isSuccess == true) {
        var products = res.result;
        this.productDataSource = products;

        this.spotEditRequest.controls['product'].setValue(0);

        //this.alertService.logMessage(this.productDataSource);
        //if (this.productDataSource.length == 1)
        //  this.uploadRequest.controls['product'].setValue(this.productDataSource[0].id);
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
        //this.productDataSource = [];
      });
  }

  private getDetail() {

    Promise.resolve(null).then(() => this.alertService.ShowLoader(true));

    this.mediaDetailService.getSpotDetail(this.spotFileGuid).subscribe((res: any) => {
      if (res.isSuccess == true) {

        this.spotMediaDetail = res.result as SpotMediaDetail;

        this.spotMediaDetail.thumbnailUrl = this.util.getThumbnailUrl(this.spotMediaDetail.thumbnailUrl, (this.spotMediaDetail.format.toLowerCase() == 'audio'), (this.spotMediaDetail.qcStatus.toLowerCase() == 'awaiting media'), (this.spotMediaDetail.qcStatus.toLowerCase() == 'creating media'));
        this.spotMediaDetail.proxyUrl = this.util.getProxyUrl(this.spotMediaDetail.proxyUrl);
        this.editorialHouseDataSource = this.spotMediaDetail.editorialHouses;
        this.agenciesDataSource = this.spotMediaDetail.agencies;
        this.clientDataSource = this.spotMediaDetail.clients;
        this.brandDataSource = this.spotMediaDetail.clientBrands;
        this.formatDataSource = this.spotMediaDetail.formats;
        this.aspectRatioDataSource = this.spotMediaDetail.aspectRatios;
        this.qcStatusDataSource = this.spotMediaDetail.qcStatuses;
        this.approvalStatusDataSource = this.spotMediaDetail.approvalStatuses;
        this.markerTypesDataSource = this.spotMediaDetail.markerTypes;
        this.productDataSource = this.spotMediaDetail.brandProducts;
        this.clientUserGroupDataSource = this.spotMediaDetail.clientUserGroups;
        this.userGrpsValue = this.spotMediaDetail.spotUserGroups;

        this.isSpotDownloadable = (this.spotMediaDetail.isMasterDownloadable == true && this.userProfileService.getUserRoles().includes(this.userRoles.getMediaDownloadRoleName()));

        this.setImageSources();
        this.setEditValues();
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }

      this.alertService.ShowLoader(false);
    },
      error => {
        this.util.handleError(error);
        this.alertService.ShowLoader(false);
        this.spotMediaDetail = null;
      });
  }

  private setEditValues() {
    this.spotEditRequest.controls['adId'].setValue(this.spotMediaDetail.adId);
    this.spotEditRequest.controls['title'].setValue(this.spotMediaDetail.title);
    this.spotEditRequest.controls['client'].setValue(this.spotMediaDetail.clientId);
    this.spotEditRequest.controls['brand'].setValue(this.spotMediaDetail.brandId);
    this.spotEditRequest.controls['product'].setValue(this.spotMediaDetail.productId == null ? 0 : this.spotMediaDetail.productId);
    this.spotEditRequest.controls['editorialHouse'].setValue(this.spotMediaDetail.editorialHouseId == null ? 0 : this.spotMediaDetail.editorialHouseId);
    this.spotEditRequest.controls['agency'].setValue(this.spotMediaDetail.agencyId == null ? 0 : this.spotMediaDetail.agencyId);
    this.spotEditRequest.controls['format'].setValue(this.spotMediaDetail.formatId);
    this.spotEditRequest.controls['aspectRatio'].setValue(this.spotMediaDetail.aspectRatio);
    this.spotEditRequest.controls['qcStatus'].setValue(this.spotMediaDetail.qcStatusId);
    this.spotEditRequest.controls['approvalStatus'].setValue(this.spotMediaDetail.approvalStatusId);
    //this.spotEditRequest.controls['expiryDate'].setValue(this.spotMediaDetail.expiryDate);
    if (this.spotMediaDetail.expiryDate) {
      this.spotMediaDetail.expiryDate = new Date(this.spotMediaDetail.expiryDate);
      this.spotMediaDetail.expiryDate.setHours(0, 0, 0, 0);
      this.spotEditRequest.controls['expiryDate'].setValue(this.spotMediaDetail.expiryDate.toISOString().substring(0, 10));
    }
    this.spotEditRequest.controls['notes'].setValue('');
    this.spotEditRequest.controls['approvalNotes'].setValue('');
    this.spotEditRequest.controls['encoded'].setValue(this.spotMediaDetail.encoded);
    this.spotEditRequest.controls['duration'].setValue(this.spotMediaDetail.duration);
    this.spotEditRequest.controls['startOfMessage'].setValue(this.spotMediaDetail.startOfMessage);
    this.spotEditRequest.controls['endOfMessage'].setValue(this.spotMediaDetail.endOfMessage);
    this.spotEditRequest.controls['lkfs'].setValue(this.spotMediaDetail.lkfs);
    this.spotEditRequest.controls['markers'].setValue(this.spotMediaDetail.markers);
    this.spotEditRequest.controls['userGroups'].setValue(this.spotMediaDetail.spotUserGroups);
    this.userGrpsValue = this.spotMediaDetail.spotUserGroups;
    this.markerValue = this.spotMediaDetail.markers;
    this.isQCNotesRequired = this.setQCNotesRequiredOrNot();
    this.isApprovalNotesRequired = this.setApprovalNotesRequiredOrNot();
    this.is169OverlayRequired = this.util.is169OverlayRequired(this.spotMediaDetail.format);
  }

  private setQCNotesRequiredOrNot() {
    if (!this.spotMediaDetail.isMasterRequiresQC)
      return false;

    var newQCValue = this.spotEditRequest.controls['qcStatus'].value;
    var newQCStatus = this.spotMediaDetail.qcStatuses.filter(t => t.id == newQCValue);
    if (newQCStatus != null && newQCStatus.length > 0 && newQCStatus[0].name == this.qcFail && this.spotMediaDetail.qcStatus != this.qcFail)
      return true;
    else
      return false;
  }


  private setApprovalNotesRequiredOrNot() {
    if (this.spotMediaDetail.enableApprovalRejection == false)
      return false;

    var newApprovalValue = this.spotEditRequest.controls['approvalStatus'].value;
    var newApprovalStatus = this.spotMediaDetail.approvalStatuses.filter(t => t.id == newApprovalValue);
    if (newApprovalStatus != null && newApprovalStatus.length > 0 && newApprovalStatus[0].name == this.rejected)
      return true;
    else
      return false;
  }

  private setImageSources() {
    this.spotMediaDetail.formatSource = this.util.getFormatSource(this.spotMediaDetail.format);
    this.spotMediaDetail.markerSources = this.util.getAllIconSources('', '', this.spotMediaDetail.spotTrac,
      this.spotMediaDetail.veilEncode, this.spotMediaDetail.subtitles, this.spotMediaDetail.centerCutProtected, this.spotMediaDetail.descriptiveVideo,
      this.spotMediaDetail.broadcast, this.spotMediaDetail.web, this.spotMediaDetail.taggable, this.spotMediaDetail.generic,
      this.spotMediaDetail.runningFootage, this.spotMediaDetail.cc, this.spotMediaDetail.sap, this.spotMediaDetail.vchip, this.spotMediaDetail.letterBox,
      this.spotMediaDetail.surround, this.spotMediaDetail.mono);

    if (this.spotMediaDetail.proxyUrl != '')
      this.getProxyUrl();
  }

  private getProxyUrl() {
    this.spotService.getProxyUrl({ objectKey: this.spotMediaDetail.proxyUrl }).subscribe((res: any) => {
      if (res.isSuccess == true) {
        this.proxyUrl = res.result;
      }
      else {
        this.util.handleIsNotSuccess(res.errors);
      }
    },
      error => {
        this.util.handleError(error);
      });
  }

  private refreshSpotDetail(newDetail: SpotMediaDetail) {
    if (newDetail.errors == null || newDetail.errors.length == 0) {
      this.alertService.showMessage("SUCCESS", Constants.spotsUpdatedSuccessfully, MessageSeverity.success);
      this.editMode = false;
      this.gs.spotEdited();

      this.spotMediaDetail = newDetail;

      //this.updatePlayerWidthClass(this.spotMediaDetail);

      this.is169OverlayRequired = this.util.is169OverlayRequired(this.spotMediaDetail.format);
      this.setImageSources();

      this.spotMediaDetail.thumbnailUrl = this.util.getThumbnailUrl(this.spotMediaDetail.thumbnailUrl, (this.spotMediaDetail.format.toLowerCase() == 'audio'), (this.spotMediaDetail.qcStatus.toLowerCase() == 'awaiting media'), (this.spotMediaDetail.qcStatus.toLowerCase() == 'creating media'));
      this.spotMediaDetail.proxyUrl = this.util.getProxyUrl(this.spotMediaDetail.proxyUrl);

      this.editorialHouseDataSource = this.spotMediaDetail.editorialHouses;
      this.agenciesDataSource = this.spotMediaDetail.agencies;
      this.clientDataSource = this.spotMediaDetail.clients;
      this.brandDataSource = this.spotMediaDetail.clientBrands;
      this.formatDataSource = this.spotMediaDetail.formats;
      this.aspectRatioDataSource = this.spotMediaDetail.aspectRatios;
      this.qcStatusDataSource = this.spotMediaDetail.qcStatuses;
      this.approvalStatusDataSource = this.spotMediaDetail.approvalStatuses;
      this.markerTypesDataSource = this.spotMediaDetail.markerTypes;
      this.productDataSource = this.spotMediaDetail.brandProducts;
      this.clientUserGroupDataSource = this.spotMediaDetail.clientUserGroups;

      this.isSpotDownloadable = (this.spotMediaDetail.isMasterDownloadable == true && this.userProfileService.getUserRoles().includes(this.userRoles.getMediaDownloadRoleName()));
    }
    else {

      var initialState = {
        errors: newDetail.errors
      };

      this.bsModalRef = this.modalService.show(ErrorPopupComponent, this.util.getModalComponentOptions(initialState, false, true, false));
    }
  }

  public downloadSpot() {

    let selectedSpotFiles: SpotFile[] = [];

    let sf = new SpotFile();
    sf.spotFileGuid = this.spotMediaDetail.spotFileGuid;
    sf.adId = this.spotMediaDetail.adId;
    sf.title = this.spotMediaDetail.title;
    sf.storageKey = this.spotMediaDetail.storageKey;
    sf.fileName = this.spotMediaDetail.masterFileName;

    selectedSpotFiles.push(sf);

    var response = this.ds.initiateS3Download(selectedSpotFiles);

    if (response == true)
      this.router.navigate([RouteConstants.mediaDownloadRoute], { queryParams: { t: "media" } });
  }

  public downloadSpotProxy() {
    this.ds.initiateQuickS3Download(this.spotMediaDetail.proxyUrl, this.spotMediaDetail.proxyFileName, false, true, false, this.spotMediaDetail.spotFileGuid);
  }
  private updatePlayerWidthClass(detail) {
    if (detail.isPostProductionRequiresApproval && detail.isPostProductionMaster) {
      this.playerWidthClass = 'col-md-12';
    }
    else {
      this.playerWidthClass = 'col-md-8';
    }
  }

  public syncTreeViewSelection(e = null) {
    var component = (e && e.component) || (this.treeUserGrps && this.treeUserGrps.instance);

    if (!component) {
      return;
    }

    if (this.userGrpsValue == null || !this.userGrpsValue.length) {
      component.unselectAll();
    }

    if (this.userGrpsValue != null) {
      this.userGrpsValue.forEach((value) => {
        component.selectItem(value);
      });
    }
  }

  public treeView_itemSelectionChanged(e) {
    this.userGrpsValue = e.component.getSelectedNodesKeys();
  }
}
