import { Injectable } from "@angular/core";

@Injectable()
export class Constants {
  public static readonly selectAll = "Select";
  public static readonly deSelectAll = "DeSelect";

  public static readonly maxLimit = 999999999;
  public static readonly carouselLength = 6;
  public static readonly passwordPattern =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
  public static readonly emailPattern =
    /^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  public static readonly phonePattern = /[0-9]*/;
  public static readonly phoneMask = [
    "(",
    /[0-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  public static readonly email = "Email";
  public static readonly SMS = "SMS";
  public static readonly statusColumnIndex = 9;
  public static readonly allOrdersGridMain = "ORDERS";
  public static readonly allOrdersGridDelivery = "DELIVERY HISTORY";
  public static readonly physicalStorageGrid = "PHYSICAL STORAGE";
  public static readonly digitalStorageGrid = "DIGITAL STORAGE";
  public static readonly openVaultRequests = "OPEN VAULT REQUESTS";
  public static readonly stagingVaults = "STAGING";
  public static readonly costCenterBillToPattern = /^((?!;).)*$/;
  public static readonly zipCodePattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
  public static readonly mainContactType = "Main Address";
  public static readonly clientUserType = "ClientUser";
  public static readonly destinationUserType = "DestinationUser";
  public static readonly timeCodeFormatMask = [
    /\d/,
    /\d/,
    ":",
    /\d/,
    /\d/,
    ":",
    /\d/,
    /\d/,
    ":",
    /\d/,
    /\d/,
  ];

  public static readonly approvalLvlDataSource = [
    {
      id: 1,
      name: "1",
    },
    {
      id: 2,
      name: "2",
    },
    {
      id: 3,
      name: "3",
    },
  ];

  //Error Messages
  public static readonly error401 = "Session Expired. Please log back in.";
  public static readonly error403 =
    "You do not have access to this Module. Please Contact your Admin.";
  public static readonly error400 =
    "Sorry! Something went wrong, please try again later.";
  public static readonly error500 =
    "Sorry! Something went wrong, please try again later.";
  public static readonly pageNotFound =
    "Oops! We can't seem to connect you now, See you later.";

  public static readonly connectionError =
    "Sorry! Even the things we love break sometimes. Please try again later.";
  public static readonly connectionLoginError =
    "Sorry! Even the things we love break sometimes. Please try again later.";
  public static readonly userNameInValid =
    "Sorry! Something went wrong, Please try again later.";

  public static readonly qcUpdateSuccess = "You updated the QC status";
  public static readonly orderReviewClaimSuccess = "You have claimed the order";
  public static readonly orderReleaseClaimSuccess =
    "You have released the order";
  public static readonly multipleOrderReleaseClaimSuccess =
    "You have released one or more orders";
  public static readonly nospotsSelected = "Please select asset";
  public static readonly noVaultAssetSelected = "Please select vault asset";

  public static readonly errorDescription = "errorDescription";
  public static readonly sharedMediaSuccessful = "You shared the asset";

  public static readonly fewSelectedSpotArePlaceholders =
    "One or more assets selected are Place holders";
  public static readonly playListDeletedSuccessfully =
    "You deleted the playlist";
  public static readonly noVideoFilesForSelectedSpots =
    "One or more assets selected has no Video files";

  public static readonly notDownloadbleForSelectedSpots =
    "One or more assets selected cannot be downloaded";

  public static readonly notPlaceholdersInSelectedSpots =
    "One or more assets selected are not Place holders";
  public static readonly selectSameVaultAssets =
    "One or more vault assets selected are not same status";
  public static readonly ineligibleAssetsSelectedForLogoutOrStaging =
    "One or more vault assets selected are not in InVault Status";
  public static readonly ineligibleAssetsSelectedForRemoveOrDestroy =
    "One or more vault assets selected are not in InVault or Staging Status";
  public static readonly loggedOutAssetsNotSelected =
    "One or more vault assets selected are not in Logged Out Status";
  public static readonly uploadSelectClientAndBrand =
    "Select Client and Brand to Upload.";
  public static readonly quickLinkNotAvaialableError =
    "Quick Link feature is not available for this asset.";
  public static readonly createPlaceHolderSuccessful =
    "You have created a new placeholder";
  public static readonly uploadedMetadataSuccessful =
    "You uploaded the Metadata Excel successfully";
  public static readonly destinationsAddedToList =
    "You updated your Destination List";
  public static readonly destinationsListCreated =
    "You created a new Destination List";
  public static readonly destinationsListDeleted =
    "You deleted your Destination List";
  public static readonly ftpDestinationDeleted =
    "You deleted your Ftp Destination";
  public static readonly spotsAddedPlaylist = "You updated your playlist";
  public static readonly playlistCreated = "You created a new playlist";
  public static readonly spotsUpdatedSuccessfully =
    "You have updated the asset";
  public static readonly spotPurgedSuccessfully = "Spot purged successfully";
  public static readonly tenOrMoreAssetsSelectedForQC =
    "More than ten assets cannot be updated at a time.";
  public static readonly fileNotSelected = "Please Select files.";

  public static readonly spotDownloadInitiated =
    "Download Initiated. Navigate to Download Status for progress.";

  public static readonly userPersonalInformationSaveSuccessful =
    "You updated your profile";
  public static readonly userPasswordUpdateSuccessful =
    "You updated your password";
  public static readonly userAvatarUpdateSuccessful = "You updated your avatar";
  public static readonly spotsExpiredSuccessfully =
    "You have set the asset expiration";
  public static readonly spotsRestoreRequestSuccessful =
    "You have sent Restore Request succcessfully";
  public static readonly placeholdersDeletedSuccessfully =
    "You have deleted the placeholders";
  public static readonly userNotificationsUpdateSuccessful =
    "You updated your notifications";
  public static readonly changePasswordError = "Password Reset Failed";
  public static readonly forgotPasswordTokenError = "Invalid Token";
  public static readonly resetPasswordEmailSent =
    "To create a new password, follow the instructions sent to: ";
  public static readonly downLoadInitiated =
    "Download initiated, Please check your downloads folder";
  public static readonly userDistroDeleted =
    "You deleted the distribution group";
  public static readonly userDistroCreated =
    "You created a new distribution group";
  public static readonly userDistroEmailUpdated =
    "You updated the distribution group";
  public static readonly userFtpDestinationCreated =
    "You created a new FTP destination";
  public static readonly userFtpDestinationUpdated =
    "You updated your FTP destination";
  public static readonly orderNetworkCarrierUpdated = "You updated a Network";
  public static readonly vendorOrderNumberUpdated =
    "You updated Network Order Number";
  public static readonly orderDeliveryPodUpdated = "You updated a Delivery POD";
  public static readonly orderNetworkPODStatusUpdated =
    "You updated a POD Status";
  public static readonly mediaType = "video/mp4";
  public static readonly avatarEncoding = "data:image/png;base64,";
  public static readonly noEmailsSelected = "Please choose one or more Emails ";
  public static readonly notificationsArchivedSuccessful = "Email(s) Archived";
  public static readonly notificationsSetAsViewwedSuccessful =
    "Email(s) Marked as Read";
  public static readonly noClientOrBrandSelected =
    "Please select both Client & Brand to add asset";
  public static readonly noGroupAdded = "Please add one or more Groups";
  public static readonly orderheaderUpdatedSuccessfully =
    "You have updated the Order header";
  public static readonly orderRetrySuccessful =
    "You submitted an order for retry";
  public static readonly orderUploadedSuccessful =
    "Uploaded orders are successfully submitted";
  public static readonly orderGpServiceLevelUpdatedSuccessfully =
    "You have updated the Group Servive Level";
  public static readonly orderGpNameUpdatedSuccessfully =
    "You have updated the Group Name";
  public static readonly groupDeleted = "You deleted the Group from the Order";
  public static readonly newGroupAdded = "You added a new Group to the Order";
  public static readonly groupUpdated = "You updated the Group";
  public static readonly orderUpdated = "You updated the Order";
  public static readonly quickLinkRequestSuccessfully =
    "You submitted a Quick Link request";

  public static readonly orderError_AssetArchived =
    "Spot Archived, Restore the spot to add other services.";

  public static readonly addServicesUpdated =
    "You updated the Additional Services";

  public static readonly newGroupMediaAdded =
    "You added a new Asset to the Group";
  public static readonly mediaInGroupDeleted =
    "You deleted  Asset from the Group";
  public static readonly groupMediaUpdated = "You updated the Asset";
  public static readonly destinationNeededForDD =
    "At least one destination is needed for Digital Delivery";
  public static readonly destinationDeleted = "You deleted the Destination";
  public static readonly newDestinationAdded =
    "You added new Destination(s) to the Group";

  public static readonly orderSubmitErrors =
    "Please fix the below errors in red.";
  public static readonly submitMandatoryFields =
    "Please fix the below errors in red.";
  public static readonly draftMandatoryFields =
    "Please fix the below errors in red.";
  public static readonly addMediaMandatoryFields =
    "Please fix the below errors in red.";
  public static readonly addOptionsMandatoryFields =
    "Please fix the below errors in red.";
  public static readonly selectAllOrderHeaderMainFields =
    "Please select Client, Brand & Order Type to continue";
  public static readonly selectMediaToApplyServices =
    "Please select Asset to add Services";
  public static readonly selectMediaToApplyTranscodes =
    "Please select Video Assets to add Transcodes";
  public static readonly draftCreated = "You created a draft";
  public static readonly orderCreated = "You created a order. PO:- ";
  public static readonly draftDeleted = "You deleted a draft order";
  public static readonly draftOrderCopied = "Draft order copied successfully";
  public static readonly noOrdersSelected = "Please select one or more Orders";
  public static readonly sixOrMoreOrdersSelected =
    "More than Five orders cannot be processed at a time.";
  public static readonly elevenOrMoreOrdersSelected =
    "More than Ten orders cannot be Released at a time.";
  public static readonly multipleOrdersSelected =
    "Please select ONLY one order to copy";
  public static readonly orderStartProcessing =
    "You have set one or more orders for processing";
  public static readonly ordersFlagged = "you have flagged some orders";
  public static readonly ordersFlaggedError =
    "we could not flag your orders, please try again later.";
  public static readonly orderCompleteProcessing =
    "You have completed processing one or more orders";
  public static readonly noOrdersSelectedForCopy =
    "Please select an order to copy";
  public static readonly orderFtpPodUpload = "You uploaded a POD";
  public static readonly orderFtpPodDeleted = "You deleted a POD";
  public static readonly orderUpdateSuccessful = "You updated the Order";
  public static readonly OrderLineItemsStatusToCheckSuccessful =
    "You updated the Order LineItems To Status Check";
  public static readonly vendorPurchaseOrderCreationSuccessful =
    "You created purchase orders";
  public static readonly vendorPurchaseOrderCreated =
    "You created purchase order";
  public static readonly orderEditStart = "You can start editing the Order now";
  public static readonly orderEditEnd = "You completed the Order edit";
  public static readonly invoiceAndBillToInitialNoDataText =
    "Please choose a product";
  public static readonly orderedByNoDataText = "No selections available";
  public static readonly invoiceAndBillToNoDataText = "No data available";
  public static readonly uploadedOrderDeleted = "You deleted a uploaded order";
  public static readonly uploadOrderFileFailed =
    "Upload failed, Please try again later.";
  public static readonly msgPostedSuccessfully = "Message posted Succesfully.";
  public static readonly orderResendUpdated =
    "You have choosen to resend asset.";

  public static readonly workflowResetSuccessfully =
    "Workflow reset Successfully.";
  public static readonly workflowOpResetSuccessfully =
    "Workflow operation reset Successfully.";
  public static readonly workflowRetryDateUpdatedSuccessfully =
    "Workflow retry date updated Successfully.";
  public static readonly workflowOpRetryDateUpdatedSuccessfully =
    "Workflow Operation retry date updated Successfully.";
  public static readonly updateOrderProduct = "Order Product updated Successfully.";

  //Order Validation errors
  public static readonly orderError_AssetExpired = "Asset Expired";
  public static readonly orderError_AssetInActive = "Asset InActive";
  public static readonly orderError_AdIdInvalid = "Ad-ID Invalid";
  public static readonly orderError_TitleInvalid = "Title Invalid";
  public static readonly orderError_OptionsMissing =
    "Options missing or invalid";
  public static readonly orderError_USClearanceServiceCombination =
    "US and International Clearance services cannot be combined with other services.";
  public static readonly orderError_TrafficOnlyDestinationCombination =
    "Traffic Only destinations cannot be combined with Digital delivery.";
  public static readonly orderError_AudioVideoCombination =
    "Audio & Video assets cannot be in the same Group for the selected services.";
  public static readonly orderError_NoDigitalDestinations =
    "No Destinations added.";
  public static readonly orderError_NoFTPDestinations =
    "No FTP Destinations added.";
  public static readonly orderError_NoClrDestinations =
    "No Clearance Destinations added.";
  public static readonly orderError_NoRelevantService =
    "No relevant Service added.";
  public static readonly orderError_DestinationAudioSpotOnly =
    "Can be added to Audio asset only.";
  public static readonly orderError_DestinationVideoSpotOnly =
    "Can be added to Video asset only.";
  public static readonly orderError_DestinationInActive =
    "Destination InActive.";
  public static readonly orderError_DestinationInValid = "Destination InValid.";

  //Admin
  public static readonly createClientSuccessful = "You created a client";
  public static readonly addNewPostHouseSuccessful = "You created a post house";
  public static readonly editClientSuccessful = "You edited a client";
  public static readonly editProductSuccessful = "You edited a product";
  public static readonly clientStatusUpdateSuccessful =
    "You updated a client status";
  public static readonly clientDraftDeleted = "You deleted a draft client";
  public static readonly makeClientBillableSuccess =
    "You made a client billable";
  public static readonly brandStatusUpdateSuccessful =
    "You updated a brand status";
  public static readonly createProductSuccessful = "You created a product";
  public static readonly createBrandSuccessful = "You created a brand";
  public static readonly editBrandSuccessful = "You edited a brand";
  public static readonly deleteBrandSuccessful = "You deleted a brand";
  public static readonly deleteProductSuccessful = "You deleted a product";
  public static readonly deletePostHouseSuccessful = "You deleted a post house";
  public static readonly productsNotSelected =
    "All selected brands require atleast one product if selection is available.";
  public static readonly createUserSuccessful = "You created a user";
  public static readonly createUserProfileSuccessful =
    "You created a user profile";
  public static readonly editUserProfileSuccessful =
    "You updated a user profile";
  public static readonly userProfileStatusUpdateSuccessful =
    "You updated a user profile status";
  public static readonly approvalLevelsNotSelected =
    "Asset Approver role requires selection of approval level for brands.";
  public static readonly passwordResetEmailSent =
    "you sent Reset Password instructions to the user.";
  public static readonly destinationMappingIdNotSelected =
    "All selected networks require a mapping Id to be added.";
  public static readonly createDestinationSuccessful =
    "You created a destination";
  public static readonly editDestinationSuccessful = "You edited a destination";
  public static readonly destinationStatusUpdateSuccessful =
    "You updated a destination status";
  public static readonly noUserProfileCreated =
    "User is Not Regiestered in Tandem Application. Please Contact support@tylie.com.";
  public static readonly authOrgCreateSuccessful = "Auth Organization Created.";

  //Verison related
  public static readonly newVersionHeader = "Newer version detected";
  public static readonly newVersionRedirectionMessage =
    "You will be redirected to the new version automatically...";

  //tmc
  public static readonly downloadInitiatedSimpleMessage = "Download Initiated";
  public static readonly downloadCompleted = "Download Completed";

  // it/ops
  public static readonly encryptionCompleted: "You encrypted a text.";

  // Project file validation
  public static readonly uploadProjectFileFailed: "Upload failed, Please try again later.";
  public static readonly multipart_chunk_size = 10485760; // 6MB
  public static readonly minprogress_chunk_size = 2291456;
  public static readonly singlepart_max_file_size = 10485760; // 10MB

  //transcode
  public static readonly transcodeorder_refCode_Updated =
    "You have updated the Reference Code";
  public static readonly transcodeorder_fileName_Updated =
    "You have updated the Filename";
  public static readonly transcodeassets_shared = "You shared the Assets";
  public static readonly transcodeorder_select =
    "Please select one or more OLV Profiles";
  public static readonly transcodeorder_list_updated = "You updated your List";
  public static readonly transcodeorder_list_created = "You created a new List";
  public static readonly transcodeorder_no_olvprofiles =
    "No OLV Profiles chosen for Assets";
  public static readonly transcodeorder_fixerrors =
    "Please fix the errors in red";
  public static readonly transcodeorder_choosemedia =
    "Please choose Assets for Transcoding";
  public static readonly transcodeorder_choosemedia_sameclienbt =
    "Please choose the Assets from the same Client";
  public static readonly transcodeorder_chooseolvprofiles_media =
    "Please choose OLV Profiles for the Assets";
  public static readonly transcodeorder_select_fileoptions =
    "Please select one or more File Name options";
  public static readonly transcode_saved_style = "You saved a new Style";
  public static readonly transcode_deleted_style = "You deleted a Style";
  public static readonly transcodeorder_complete_option_1_2 =
    "Please complete either Option 1 or Option 2";
  public static readonly transcodeorder_complete_option_2 =
    "Please complete Option 2";
  public static readonly transcodeorder_list_deleted = "You deleted a List";
  public static readonly transcode_selectfiles_download =
    "Please select one or more Assets to Download";
  public static readonly transcode_selectfiles_share =
    "Please select one or more Assets to Share";

  // Project promoted
  public static readonly project_promote_success =
    "Project file promoted successfully.";

  // Vault
  public static readonly vaultCreated = "Vaultrequest created Successfully.";
  public static readonly faildtoLoadEditConfig =
    "Failed to load Edit Config. Please try again.";
  public static readonly assetStatusLoginUpdated =
    "Vault Assets Status updated to Login Successfully.";
  public static readonly assetStatusLogoutUpdated =
    "Vault Assets Status updated to Logout Successfully.";
  public static readonly assetStatusRemoveUpdated =
    "Vault Assets Status updated to Remove Successfully.";
  public static readonly assetStatusStagingUpdated =
    "Vault Assets Status updated to Staging Successfully.";
  public static readonly assetStatusUpdatedFail =
    "Failed to update Vault Asset Status. Please try again.";
  public static readonly failed_upadate_asset =
    "Failed to update Vault Asset. Please try again.";
  public static readonly failed_upadate_asset_digital =
    "Failed to update Vault Digital Asset. Please try again.";
  public static readonly success_upadate_asset =
    "Vault Asset Updated successfully.";
  public static readonly success_upadate_asset_digital =
    "Vault Digital Asset Updated successfully.";
  public static readonly orderVaultHeaderUpdatedSuccessfully =
    "You have updated the Vault Header";
  public static readonly orderVaultUpdatedSuccessfully =
    "You have updated the Vault Request";
  public static readonly success_add_vault_asset_note =
    "Notes added successfully.";
  public static readonly failed_add_vault_asset_note =
    "Failed to add Vaultasset note. Please try again.";
  public static readonly success_archive_order = "Archive successfully.";
  public static readonly failed_archive_order =
    "Failed to Archive. Please try again.";
  public static readonly success_unarchive_order = "Unarchive successfully.";
  public static readonly failed_unarchive_order =
    "Failed to Unarchive. Please try again.";

  // Tagger
  public static readonly createTaggerSuccessful = "You created a Ad";
  public static readonly draftTaggerSuccessful = "You created a Ad as draft";
  public static readonly updateTaggerSuccessful = "You updated a Ad";
  public static readonly failed_add_note =
    "Failed to add note. Please try again.";
  public static readonly tagger_ad_share =
    "Please select one or more Ad to Share";
  public static readonly tagger_ad_shared = "You shared the Ads";
  public static readonly creative_duration_error = "Taggable Duration is not available on this Creative";
  public static readonly voice_over_duration_error = "Taggable Duration is not available on this Voice over";

  // Tagger Assets
  public static readonly updateTaggerAssetSuccessful =
    "You successfully updated a Asset";
  public static readonly uploadTaggerAssetSuccessful =
    "You successfully uploaded Asset/s";
  public static readonly deleteTaggerAssetSuccessful =
    "You successfully deleted Asset";

  // Tagger Groups & Locations
  public static readonly createTaggerGroupSuccessful =
    "You successfully created a Group.";
  public static readonly updateTaggerGroupSuccessful =
    "You successfully updated a Group.";
  public static readonly noLocationsSelected = "Please select location.";
  public static readonly updateTaggerGroupLocationsSuccessful =
    "You successfully updated Locations for this Group.";
  public static readonly failed_add_user = "Failed to add User. Please try again.";
  public static readonly success_add_user = "User Added Successfully.";
  public static readonly failed_add_location = "Failed to add Location. Please try again.";
  public static readonly success_add_location = "Location Added Successfully.";
  public static readonly failed_update_location = "Failed to Update Location. Please try again.";
  public static readonly success_update_location = "Location Updated Successfully.";
  public static readonly failed_update_location_groups = "Failed to Update Location Groups. Please try again.";
  public static readonly success_update_location_groups = "Location Groups Updated Successfully.";
  public static readonly error_file_format = "Please select correct File format.";
  public static readonly group_location_mandatery = "Groups and Locations are mandatory.";

  //grids
  public static readonly failed_grid_columns = "Error in loading grid columns. Please try again.";

  //grid names
  public static readonly SEARCHALLASSETS = "SEARCHALLASSETS";
}
