<header class="tylie-header">
  <h3 class="tylie-header__title">Vault</h3>
</header>

<div class="tylie-body">
  <div class="tylie-body__bar">
    <button type="button" class="tylie-button tylie-button--icon" (click)="gotoVaultCreate()">
      <span class="tylie-button__icon"><icon-plus-circle></icon-plus-circle></span>
      <span class="tylie-button__text">CREATE NEW VAULT</span>
    </button>
  </div>



  <ul class="tylie-tab-nav nav nav-tabs mx-4" id="myTab" role="tablist">
    <li class="tylie-tab-nav__itm nav-item" role="presentation">
      <button class="tylie-tab-nav__lnk nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#vaultview" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" (click)="onclickOpenVaultRequests()">OPEN VAULT REQUESTS</button>
    </li>
    <li class="tylie-tab-nav__itm nav-item" role="presentation">
      <button class="tylie-tab-nav__lnk nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#stageVaultView" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" (click)="onclickStaging()">Staging</button>
    </li>
  </ul>

  <div class="tylie-tab-content tab-content">
    <div id="vaultview" class="tylie-tab-content__pane tab-pane fade show active">
      <dx-data-grid id="vaultSearch"
                    #vaultSearch class="tylie-grid"
                    [dataSource]="resultVaultStore"
                    [allowColumnReordering]="true"
                    [allowColumnResizing]="true"
                    [columnAutoWidth]="true"
                    [showColumnLines]="true"
                    [showRowLines]="true"
                    [hoverStateEnabled]="true"
                    keyExpr="Vaultrequestid"
                    [rowAlternationEnabled]="true"
                    [showBorders]="true"
                    (onRowPrepared)="onRowPrepared($event)">
        <dxi-column dataField="createdDate" cellTemplate="datetimeTemplate" caption="Created" cssClass="gridStyle tyl-nopadding tyl-grid-padding" sortOrder="desc" width="12%"></dxi-column>
        <dxi-column dataField="vaultRequestNumber" cellTemplate="vaultRequestNumberTemplate" caption="Vault Request Number" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
        <dxi-column dataField="client" caption="Client" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
        <dxi-column dataField="brand" caption="Brand" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="8%"></dxi-column>
        <dxi-column dataField="product" caption="Product" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
        <dxi-column dataField="jobnumber" caption="Job#" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="8%"></dxi-column>
        <dxi-column dataField="reference" caption="Reference" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="8%"></dxi-column>
        <dxi-column dataField="warehouse" caption="Warehouse" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="11%"></dxi-column>
        <dxi-column dataField="vaultRequestTypeName" caption="Vault Request Type" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="11%"></dxi-column>

        <div *dxTemplate="let t of 'vaultRequestNumberTemplate'">
          <span class="quickLink" (click)='showVaultRequesDetail(t.data.vaultRequestGuid, "OPEN VAULT REQUEST")'>{{t.data.vaultRequestNumber}}</span>
        </div>

        <div *dxTemplate="let data of 'datetimeTemplate'">
          <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY hh:mm A'}}</div>
        </div>

        <dxo-remote-operations [sorting]="true"
                                [paging]="true">
        </dxo-remote-operations>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true"
                    [visible]="true"
                    [allowedPageSizes]="[10,20, 50, 100]"
                    infoText="Page {0} of {1} ({2} items)"
                    [showNavigationButtons]="true"
                    [showInfo]="true">
        </dxo-pager>
      </dx-data-grid>
    </div>
    <div id="stageVaultView" class="tylie-tab-content__pane tab-pane tab-pane fade">
      <dx-data-grid id="stageVaultGrid"
                    #stageVaultGrid class="tylie-grid"
                    [dataSource]="resultStagingVaultViewStore"
                    [allowColumnReordering]="true"
                    [allowColumnResizing]="true"
                    [columnAutoWidth]="true"
                    [showColumnLines]="true"
                    [showRowLines]="true"
                    [hoverStateEnabled]="true"
                    keyExpr="Vaultrequestid"
                    [rowAlternationEnabled]="true"
                    [showBorders]="true"
                    (onRowPrepared)="onRowPrepared($event)">
        <dxi-column dataField="createdDate" cellTemplate="datetimeTemplate" caption="Created" cssClass="gridStyle tyl-nopadding tyl-grid-padding" sortOrder="desc" width="14%"></dxi-column>
        <dxi-column dataField="vaultRequestNumber" cellTemplate="vaultRequestNumberTemplate" caption="Vault Request Number" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
        <dxi-column dataField="client" caption="Client" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
        <dxi-column dataField="brand" caption="Brand" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
        <dxi-column dataField="product" caption="Product" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
        <dxi-column dataField="jobnumber" caption="Job#" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
        <dxi-column dataField="reference" caption="Reference" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="10%"></dxi-column>
        <dxi-column dataField="warehouse" caption="Warehouse" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>
        <dxi-column dataField="vaultRequestTypeName" caption="Vault Request Type" cssClass="gridStyle tyl-nopadding tyl-grid-padding" width="12%"></dxi-column>

        <div *dxTemplate="let t of 'vaultRequestNumberTemplate'">
          <span class="quickLink" (click)='showVaultRequesDetail(t.data.vaultRequestGuid, "STAGING")'>{{t.data.vaultRequestNumber}}</span>
        </div>

        <div *dxTemplate="let data of 'datetimeTemplate'">
          <div style="text-align:left;">{{ data.value | usertimezone:'MM/DD/YYYY hh:mm A'}}</div>
        </div>

        <dxo-remote-operations [sorting]="true"
                                [paging]="true">
        </dxo-remote-operations>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true"
                    [visible]="true"
                    [allowedPageSizes]="[10, 20, 50, 100]"
                    infoText="Page {0} of {1} ({2} items)"
                    [showNavigationButtons]="true"
                    [showInfo]="true">
        </dxo-pager>
      </dx-data-grid>
    </div>
  </div>
 


    <div class="mt-5">
      <app-vault-search></app-vault-search>
    </div>
  </div>




