<div class="tylie-profile">
  <div class="tylie-profile__content" *ngIf="this.hasMultipleprofiles == true">
    <div class="tylie-profile__logo imgcontainer">
      <img src="./assets/svg/TylieTandem-Logo.svg" alt="Avatar" class="tylie-profile__logo-img">
    </div>

    <div class="tylie-profile__body media-awaiting-approval-section animated zoomIn">
      <div class="tylie-panel tylie-panel--no-br">
        <div class="tylie-panel__header">
          <h4 class="tylie-panel__title">
            tylie platform login
          </h4>
        </div>
        <div  class="tylie-panel__body" id="mediaApprovalCollapseOne">
          
            <h2 class="tylie-panel__body-title">Please select a profile</h2>
            
            <div class="alert alert-danger animated shake" *ngIf="loginErrorMsg">
              {{loginErrorMsg}}
            </div>
            <div class="tylie-profile__items">
              <ng-container *ngFor="let prof of userProfileSelectionsList">
                <div (click)="onSelectProfile(prof.userProfileGuid, prof.authIdentifier)" class="tylie-profile__item">
                    <div class="tylie-profile__item-img">
                      <img src="{{prof.avatar == null ? this.config.defaultAvatar : avatarEnconding + prof.avatar}}">
                    </div>
                    <div class="tylie-profile__item-content">
                      <h3 class="tylie-profile__title">{{prof.fullName}}</h3>
                      <div class="tylie-data tylie-data--c-block"><span class="tylie-data__lbl" *ngIf="prof.destination">Destination: {{prof.destination}}</span></div>
                      <div class="tylie-data tylie-data--c-block"><span class="tylie-data__lbl" *ngIf="prof.clientName">Client: {{prof.clientName}}</span></div>
                      <div class="tylie-data tylie-data--c-block"><span class="tylie-data__lbl" *ngIf="prof.editorialHouseName">Post House: {{prof.editorialHouseName}}</span></div>
                    </div>
                
                </div>
              </ng-container>
            </div>
            <div class="d-flex justify-content-end">
              <button class="tylie-button tylie-button--link tylie-button--xs" type="button" (click)="logout()">Back to Login</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
